import React from 'react'
import { FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import { IoLogoWhatsapp } from 'react-icons/io'

export const socialLinks = {
  twitter: 'https://twitter.com/penny_norton',
  linkedIn: 'https://www.linkedin.com/in/pennynorton',
}

export default class SocialButtons extends React.Component {
  render() {
    return (
      <div className="social-buttons py-4">
        <a
          className="mx-auto block md:inline-block md:mr-2 mb-3 md:mb-0 button icon-button linkedin"
          href={socialLinks.linkedIn}
          target="_blank"
        >
          <FaLinkedinIn />
          {' '}
LinkedIn
        </a>
        <a
          className="mx-auto block md:inline-block md:mr-2 mb-3 md:mb-0 button icon-button twitter"
          href={socialLinks.twitter}
          target="_blank"
        >
          <FaTwitter />
          {' '}
Twitter
        </a>
      </div>
    )
  }
}
