import React from 'react'
import { Link, StaticQuery, graphql, navigate } from 'gatsby'
import * as EmailValidator from 'email-validator'
import SocialButtons from '../components/SocialButtons/SocialButtons'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from 'react-twitter-embed'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { submitError: null }
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({ [e.target.name]: value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({
      submitError: null,
      invalidEmail: false,
    })

    if (
      this.state.agreement &&
      this.state.name &&
      this.state.email &&
      this.state.message &&
      EmailValidator.validate(this.state.email)
    ) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact',
          ...this.state,
        }),
      })
        .then(() => {
          navigate('/thanks')
        })
        .catch(error => alert(error))
    } else {
      this.setState({
        submitError:
          'Please make sure all required fields are filled in and you have agreed to the privacy policy.',
        invalidEmail: !EmailValidator.validate(this.state.email),
      })
    }
  }

  drawContactForm() {
    return (
      <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>

        <div className="field">
          <div className="control">
            <input
              className={`input is-medium ${
                this.state.submitError && !this.state.name ? 'is-danger' : ''
              }`}
              type="text"
              name="name"
              onChange={this.handleChange}
              placeholder="Your Name*"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <input
              className={`input is-medium ${
                this.state.submitError &&
                (!this.state.email || this.state.invalidEmail)
                  ? 'is-danger'
                  : ''
              }`}
              type="text"
              name="email"
              onChange={this.handleChange}
              placeholder="Your Email*"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <input
              className="input is-medium"
              type="text"
              name="telephone"
              onChange={this.handleChange}
              placeholder="Telephone"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <textarea
              className={`textarea is-medium ${
                this.state.submitError && !this.state.message ? 'is-danger' : ''
              }`}
              type="text"
              name="message"
              rows="8"
              onChange={this.handleChange}
              placeholder="Your Message*"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <label className="checkbox">
              <input
                type="checkbox"
                onChange={this.handleChange}
                name="agreement"
              />
              <span className="is-size-6">
                I acknowledge and agree to PNPR Limited's{' '}
                <Link to="/privacy-policy">privacy policy</Link>
              </span>
            </label>
          </div>
        </div>
        <p className="pt-3">
          <button className="button pink" type="submit">
            <span>Send</span>
          </button>
        </p>
        {this.state.submitError && (
          <div className="form-error notification is-danger mt-3">
            {this.state.submitError}
          </div>
        )}
      </form>
    )
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact Us" />
        <div className="container mx-auto max-w-6xl px-8">
          <div className="flex flex-wrap">
            <div className="sm:w-1/2">
              <h1 className="underlined">Contact Us</h1>
              <div>
                <div className="block">
                  <div className="social-media">
                    <p className="text-lg sm:text-xl mb-4">
                      Please contact us if you’d like to find out more about
                      PNPR and examples of the company’s work.
                    </p>

                    <SocialButtons type="contact-page" />
                  </div>
                </div>
                <hr />
                <div className="block container max-w-xl mx-auto">
                  <h3>
                    <span>Email Directly</span>
                  </h3>
                  {this.drawContactForm()}
                </div>
              </div>
            </div>
            <div className="latest-tweets-wrapper sm:w-1/2 sm:px-10">
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="penny_norton"
                autoHeight
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactUsPage
